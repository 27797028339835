import {ProjectFile, ProjectFileUrl} from "@co-common-libs/resources";
import {DeleteDialog, SingleTextFieldDialog, useModal} from "@co-frontend-libs/components";
import {actions, getProjectFileLookup, getShareToken} from "@co-frontend-libs/redux";
import {IconButton, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import DeleteIcon from "mdi-react/DeleteIcon";
import FileIcon from "mdi-react/FileIcon";
import PencilIcon from "mdi-react/PencilIcon";
import React, {useCallback} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {InstanceClickIconButton} from "../instance-click-icon-button";
import {VisibleToAllCheckbox} from "./visible-to-all-checkbox";

const ICON_WIDTH = 48;
const PADDING = 24;
const VISIBLE_TO_ALL_COLUMN_WIDTH = 120;

interface ProjectFilesTableRowProps {
  canEditProjectFiles: boolean;
  canManageProjectFiles: boolean;
  file: ProjectFile;
  onDeleteClicked: (url: ProjectFileUrl) => void;
  onRenameClicked: (url: ProjectFileUrl) => void;
}

export function ProjectFilesTableRow(props: ProjectFilesTableRowProps): JSX.Element {
  const {canEditProjectFiles, canManageProjectFiles, file, onDeleteClicked, onRenameClicked} =
    props;

  const ICON_COLUMN_WIDTH = ICON_WIDTH + 2 * PADDING + (canManageProjectFiles ? 2 * ICON_WIDTH : 0);

  const shareToken = useSelector(getShareToken);
  const handleDelete = useCallback(() => onDeleteClicked(file.url), [onDeleteClicked, file.url]);

  const handleRename = useCallback(() => {
    onRenameClicked(file.url);
  }, [onRenameClicked, file.url]);

  return (
    <TableRow key={file.url}>
      <TableCell>{file.name}</TableCell>
      {canEditProjectFiles && canManageProjectFiles ? (
        <TableCell style={{width: VISIBLE_TO_ALL_COLUMN_WIDTH}}>
          <VisibleToAllCheckbox checked={file.visibleToAll} instanceURL={file.url} />
        </TableCell>
      ) : null}
      <TableCell style={{width: ICON_COLUMN_WIDTH}}>
        {canManageProjectFiles ? (
          <>
            <InstanceClickIconButton instance={file.url} onClick={handleRename}>
              <PencilIcon />
            </InstanceClickIconButton>
            <InstanceClickIconButton instance={file.url} onClick={handleDelete}>
              <DeleteIcon />
            </InstanceClickIconButton>
          </>
        ) : null}
        <IconButton color="primary" href={`${file.download}?token=${shareToken}`} target="_blank">
          <FileIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

interface ProjectFilesTableProps {
  canEditProjectFiles: boolean;
  canManageProjectFiles: boolean;
  files: ProjectFile[];
}

export function ProjectFilesTable(props: ProjectFilesTableProps): JSX.Element {
  const projectFileLookup = useSelector(getProjectFileLookup);
  const dispatch = useDispatch();
  const intl = useIntl();

  const {canEditProjectFiles, canManageProjectFiles, files} = props;
  const ICON_COLUMN_WIDTH = ICON_WIDTH + 2 * PADDING + (canManageProjectFiles ? 2 * ICON_WIDTH : 0);

  const [deleteDialog, promptForDelete] = useModal(DeleteDialog);
  const [nameDialog, promptForRename] = useModal(SingleTextFieldDialog);

  const handleRename = useCallback(
    async (url: ProjectFileUrl) => {
      const projectFile = projectFileLookup(url);
      if (!projectFile) {
        return;
      }
      const newName = await promptForRename({
        label: <FormattedMessage defaultMessage="Navn" />,
        title: intl.formatMessage({defaultMessage: "Omdøb fil"}),
        value: projectFile.name,
      });
      if (newName !== undefined) {
        dispatch(actions.update(projectFile.url, [{member: "name", value: newName}]));
      }
    },
    [projectFileLookup, promptForRename, dispatch, intl],
  );

  const handleDelete = useCallback(
    async (url: string) => {
      await promptForDelete({
        children: <FormattedMessage defaultMessage="Sikker på at du vil slette filen?" />,
      });
      dispatch(actions.remove(url));
    },
    [promptForDelete, dispatch],
  );

  if (!files.length) {
    return (
      <div style={{padding: 8, textAlign: "center"}}>
        <FormattedMessage defaultMessage="Der er ingen filer tilknyttet projektet." />
      </div>
    );
  }

  const tableRowList = files.map((file) => {
    return (
      <ProjectFilesTableRow
        key={file.url}
        canEditProjectFiles={canEditProjectFiles}
        canManageProjectFiles={canManageProjectFiles}
        file={file}
        onDeleteClicked={handleDelete}
        onRenameClicked={handleRename}
      />
    );
  });

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage defaultMessage="Navn" />
            </TableCell>
            {canManageProjectFiles ? (
              <TableCell style={{width: VISIBLE_TO_ALL_COLUMN_WIDTH}}>
                <FormattedMessage defaultMessage="Synlig for alle" />
              </TableCell>
            ) : null}
            <TableCell style={{width: ICON_COLUMN_WIDTH}}>
              <FormattedMessage defaultMessage="Download" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{tableRowList}</TableBody>
      </Table>
      {deleteDialog}
      {nameDialog}
    </>
  );
}
