import {Task, UserUrl} from "@co-common-libs/resources";
import {getNormalisedDeviceTimestamp} from "@co-common-libs/resources-utils";
import {dateToString} from "@co-common-libs/utils";
import {
  getCustomerSettings,
  getRoleArray,
  getTaskArray,
  getTimerStartArray,
} from "@co-frontend-libs/redux";
import _ from "lodash";
import React from "react";
import {useSelector} from "react-redux";
import {tasksForDate} from "./task-dates";
import {computeIntervalsTruncated, mergeIntervals} from "./task-timers";

interface BookkeepingTasksParams {
  employeeUrl: UserUrl;
  selectedDate: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useBookkeepingTasks = (params: BookkeepingTasksParams) => {
  const {employeeUrl, selectedDate} = params;

  const customerSettings = useSelector(getCustomerSettings);
  const timerStartArray = useSelector(getTimerStartArray);
  const taskArray = useSelector(getTaskArray);
  const roleArray = useSelector(getRoleArray);

  return React.useMemo(() => {
    const tasksMatchingEmployee = taskArray.filter((task) => task.machineOperator === employeeUrl);

    const timerStartSeq = timerStartArray.filter(
      (timerStart) => timerStart.employee === employeeUrl,
    );

    const {
      incompleteTasks,
      intersectingCompletedTasks,
      sortedTimerStarts,
      startOfDay: startOfSelectedDate,
      startOfNextDay,
    } = tasksForDate(
      selectedDate,
      tasksMatchingEmployee,
      timerStartSeq,
      roleArray,
      customerSettings.onlyManagerCreatedTasksCalendarPlanned,
    );

    const machineOperatorIncompleteTaskList = incompleteTasks.filter(
      (task) => task.machineOperator === employeeUrl,
    );

    const startedOnDayIncompleteTaskList = machineOperatorIncompleteTaskList.filter((task) => {
      const taskURL = task.url;
      let startTimestamp: string | null = null;
      if (task.workFromTimestamp) {
        startTimestamp = task.workFromTimestamp;
      } else {
        const firstTimerStart = sortedTimerStarts.find((t) => t.task === taskURL && t.timer);
        if (firstTimerStart) {
          startTimestamp = getNormalisedDeviceTimestamp(firstTimerStart);
        }
      }
      if (startTimestamp) {
        return startTimestamp > startOfSelectedDate && startTimestamp < startOfNextDay;
      } else {
        return false;
      }
    });

    const plannedOnDayIncompleteTaskList = machineOperatorIncompleteTaskList
      .filter((task) => {
        const {workFromTimestamp} = task;
        if (workFromTimestamp) {
          return dateToString(new Date(workFromTimestamp)) === selectedDate;
        } else {
          return task.date === selectedDate;
        }
      })
      .filter((task) => !startedOnDayIncompleteTaskList.includes(task));

    const now = new Date();

    const finalIncompleteTaskList = startedOnDayIncompleteTaskList
      .map((task) => {
        const taskURL = task.url;
        const timerStartList = _.sortBy(
          timerStartArray.filter((t) => t.task === taskURL),
          getNormalisedDeviceTimestamp,
        );
        const computedIntervals = computeIntervalsTruncated(timerStartList);
        const correctionIntervals = task.machineOperatorTimeCorrectionSet || [];
        const managerCorrectionIntervals = task.managerTimeCorrectionSet || [];
        const intervals = mergeIntervals(
          computedIntervals,
          correctionIntervals,
          managerCorrectionIntervals,
        );
        let finalStartTime: string | undefined;
        let finalEndTime: string | undefined;
        if (intervals.length) {
          finalStartTime = intervals[0].fromTimestamp;
          finalEndTime = intervals[intervals.length - 1].toTimestamp || now.toISOString();
        }
        if (finalStartTime) {
          fromTimestampArray.push(finalStartTime);
        }
        if (finalEndTime) {
          toTimestampArray.push(finalEndTime);
        }
        const result: Task = {
          ...task,
          workFromTimestamp: finalStartTime || null,
          workToTimestamp: finalEndTime || null,
        };
        return result;
      })
      .concat(plannedOnDayIncompleteTaskList);

    const fromTimestampArray: string[] = [];
    const toTimestampArray: string[] = [];

    const completedTasksStartedToday = (
      intersectingCompletedTasks.filter(
        (task) => task.workFromTimestamp && task.workToTimestamp,
      ) as (Task & {
        workFromTimestamp: string;
        workToTimestamp: string;
      })[]
    ).filter((task) => task.workFromTimestamp >= startOfSelectedDate);
    completedTasksStartedToday.forEach((task) => {
      fromTimestampArray.push(task.workFromTimestamp);
      toTimestampArray.push(task.workToTimestamp);
    });

    const extraTasks = tasksMatchingEmployee.filter(
      (task) => task.completed && !task.workFromTimestamp && task.date === selectedDate,
    );

    const someIncomplete =
      !!startedOnDayIncompleteTaskList.length || !!plannedOnDayIncompleteTaskList.length;

    return {
      completedTasksStartedToday,
      extraTasks,
      finalIncompleteTaskList,
      fromTimestampArray,
      incompleteTasks,
      intersectingCompletedTasks,
      someIncomplete,
      sortedTimerStarts,
      toTimestampArray,
    };
  }, [
    customerSettings.onlyManagerCreatedTasksCalendarPlanned,
    employeeUrl,
    roleArray,
    selectedDate,
    taskArray,
    timerStartArray,
  ]);
};
