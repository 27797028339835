import React, {useCallback, useRef, useState} from "react";
import {ResponsiveDialog} from "../responsive-dialog";

interface ModalPromptComponentProps<ReturnType> {
  onCancel: () => void;
  onOk: (result: ReturnType) => void;
}

type ModalComponent<ReturnType, PropsType> = React.ComponentType<
  PropsType & ModalPromptComponentProps<ReturnType>
>;

type AdditionalPropsType<ReturnType, PropsType> = Omit<
  PropsType,
  keyof ModalPromptComponentProps<ReturnType> | "onNone" | "open"
>;

export const useModal = <ReturnType, PropsType>(
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ModalContent: ModalComponent<ReturnType, PropsType>,
  title: string = "",
): [
  JSX.Element,
  (additionalProps?: AdditionalPropsType<ReturnType, PropsType>) => Promise<ReturnType | undefined>,
] => {
  const [isOpen, setIsOpen] = useState(false);

  const additionalPropsRef = useRef<AdditionalPropsType<ReturnType, PropsType> | undefined>(
    undefined,
  );
  const resolverRef = useRef<((value: ReturnType | undefined) => void) | null>(null);

  const prompt = (
    additionalProps?: AdditionalPropsType<ReturnType, PropsType>,
  ): Promise<ReturnType | undefined> => {
    return new Promise<ReturnType | undefined>((resolve) => {
      if (resolverRef.current) {
        resolverRef.current(undefined);
      }

      additionalPropsRef.current = additionalProps;
      resolverRef.current = resolve;
      setIsOpen(true);
    });
  };

  const onOk = useCallback((result: ReturnType) => {
    if (resolverRef.current) {
      resolverRef.current(result);
    }
    setIsOpen(false);
    resolverRef.current = null;
  }, []);

  const onCancel = useCallback(() => {
    if (resolverRef.current) {
      resolverRef.current(undefined);
    }
    setIsOpen(false);
    resolverRef.current = null;
  }, []);

  const modal = (
    <ResponsiveDialog hideActions open={isOpen} title={title} onCancel={onCancel}>
      <ModalContent
        open={isOpen}
        onCancel={onCancel}
        onOk={onOk}
        {...(additionalPropsRef.current as PropsType)}
      />
    </ResponsiveDialog>
  );

  return [modal, prompt];
};
