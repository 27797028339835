import {getTimerArray} from "@co-frontend-libs/redux";
import {Menu, MenuProps} from "@material-ui/core";
import {SPACING, theme} from "frontend-global-config";
import {sortBy} from "lodash";
import React from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {Alert} from "./alert";
import {AlertMenuItem} from "./alert-menu-item";

interface AlertMenuProps extends Pick<MenuProps, "anchorEl" | "onClose" | "open"> {
  alerts: readonly Alert[];
  onAlertClick(alert: Alert): void;
}

export function AlertMenu(props: AlertMenuProps): JSX.Element {
  const {alerts, anchorEl, onAlertClick, onClose, open} = props;

  const timerArray = useSelector(getTimerArray);
  const notifyUsersOnStartTimerLabels = sortBy(
    timerArray
      .filter(({active, notifyUsersOnStart}) => active && notifyUsersOnStart)
      .map(({label}) => label),
  );
  return (
    <Menu
      keepMounted
      anchorEl={anchorEl}
      open={open}
      style={{marginTop: theme.spacing(SPACING.MEDIUM)}}
      onClose={onClose}
    >
      {alerts.length > 0 ? (
        alerts.map((alert, index) => (
          <AlertMenuItem key={index} alert={alert} onClick={onAlertClick} />
        ))
      ) : (
        <div style={{padding: 6}}>
          <FormattedMessage
            defaultMessage={"Ingen aktive tidsregisteringer på: {notifyUsersOnStartTimerLabels}"}
            id={"alert-menu.label.no-active-timers"}
            values={{notifyUsersOnStartTimerLabels}}
          />
        </div>
      )}
    </Menu>
  );
}
